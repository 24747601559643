<template>
    <TheLayout>
        <AppHeader class="u-margin-bottom-large">
            <AppTitle v-translate class="header__title">
                Wachtwoord instellen
            </AppTitle>
        </AppHeader>

        <AppForm ref="form" lazy-validation @submit.prevent="formSubmit">
            <AppFormGroup>
                <AppInputText
                    v-model="formValues.newPassword1"
                    autocomplete="new-password"
                    :disabled="loading > 0"
                    :error-messages="formErrors.newPassword1"
                    :label="$gettext('Nieuw wachtwoord')"
                    required
                    type="password"
                />

                <AppInputText
                    v-model="formValues.newPassword2"
                    autocomplete="new-password"
                    :disabled="loading > 0"
                    :error-messages="formErrors.newPassword2"
                    :label="$gettext('Herhaal wachtwoord')"
                    required
                    type="password"
                />
            </AppFormGroup>

            <AppFormGroup align="right">
                <AppButton
                    v-translate
                    :disabled="!onLine"
                    :loading="loading > 0"
                    :title="$gettext('Inloggen')"
                    type="submit"
                    variant="secondary huge full-mobile"
                >
                    Verzenden
                </AppButton>
            </AppFormGroup>

            <AppFormGroup>
                <AppLink
                    v-translate
                    :to="{ name: 'login' }"
                    variant="underline large"
                >
                    Terug naar inloggen
                </AppLink>
            </AppFormGroup>
        </AppForm>
    </TheLayout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import AppButton from "@/components/AppButton";
import AppForm from "@/components/AppForm";
import AppFormGroup from "@/components/AppFormGroup";
import AppHeader from "@/components/AppHeader";
import AppInputText from "@/components/AppInputText";
import AppLink from "@/components/AppLink";
import AppTitle from "@/components/AppTitle";
import FormSave from "@/mixins/FormSave";
import TheLayout from "@/components/TheLayoutAuth";

import { camelCase } from "lodash";
import gql from "graphql-tag";

export default {
    components: {
        AppLink,
        AppFormGroup,
        AppTitle,
        AppHeader,
        AppButton,
        AppInputText,
        AppForm,
        TheLayout
    },
    mixins: [FormSave],
    props: {
        uid: {
            type: String,
            default: undefined
        },
        token: {
            type: String,
            default: undefined
        }
    },
    data() {
        return {
            loading: 0,
            formValues: {
                newPassword1: "",
                newPassword2: ""
            },
            emptyFormErrors: {
                newPassword1: [],
                newPassword2: []
            }
        };
    },
    computed: {
        ...mapGetters("auth", [
            "userLoggedIn",
            "passwordResetId",
            "passwordResetToken"
        ]),
        ...mapGetters("online", ["onLine"])
    },
    watch: {
        userLoggedIn(value) {
            if (value) {
                this.$router.push({
                    name: "dashboard"
                });
            }
        }
    },
    created() {
        if (!this.userLoggedIn) {
            if (this.uid && this.token) {
                this.passwordResetTokens({
                    uid: this.uid,
                    token: this.token
                });
                this.$router.push({
                    name: "password_reset_confirm_redirect"
                });
            } else if (!this.passwordResetId || !this.passwordResetToken) {
                this.$router.push({
                    name: "login"
                });
            }
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        ...mapMutations("auth", ["passwordResetTokens"]),
        getMutation() {
            const input = {
                uidb64: this.passwordResetId,
                token: this.passwordResetToken,
                newPassword1: this.formValues.newPassword1,
                newPassword2: this.formValues.newPassword2
            };

            return {
                mutation: gql`
                    mutation passwordResetConfirm(
                        $input: PasswordResetConfirmMutationInput!
                    ) {
                        passwordResetConfirm(input: $input) {
                            errors {
                                field
                                messages
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                },
                fetchPolicy: "no-cache"
            };
        },
        formSuccess() {
            this.passwordResetTokens();
            this.$router.push({
                name: "password_reset_completed"
            });
        },
        formFailed(errors) {
            for (const err of errors) {
                if (err.field === "uidb64" || err.field === "token") {
                    this.showMessage({
                        message: err.messages.join("\n"),
                        type: "error"
                    });
                    this.formErrors = { ...this.emptyFormErrors };
                    break;
                }
                this.formErrors[camelCase(err.field)] = err.messages;
            }
        }
    }
};
</script>
