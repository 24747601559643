<template>
    <TheLayout>
        <AppHeader class="u-margin-bottom-large">
            <AppTitle v-translate class="header__title">
                Wachtwoord vergeten
            </AppTitle>
        </AppHeader>

        <AppForm ref="form" lazy-validation @submit.prevent="formSubmit">
            <AppFormGroup>
                <AppInputText
                    v-model="formValues.email"
                    autocomplete="email"
                    :disabled="loading > 0"
                    :error-messages="formErrors.email"
                    :label="$gettext('E-mailadres')"
                    required
                    type="email"
                />
            </AppFormGroup>
            <AppFormGroup align="right">
                <AppButton
                    v-translate
                    :disabled="!onLine"
                    :loading="loading > 0"
                    type="submit"
                    variant="secondary huge full-mobile"
                >
                    Wijzig wachtwoord
                </AppButton>
            </AppFormGroup>
            <AppFormGroup>
                <AppLink
                    v-translate
                    :to="{ name: 'login' }"
                    variant="large underline"
                >
                    Terug naar inloggen
                </AppLink>
            </AppFormGroup>
        </AppForm>
    </TheLayout>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppForm from "@/components/AppForm";
import AppFormGroup from "@/components/AppFormGroup";
import AppHeader from "@/components/AppHeader";
import AppInputText from "@/components/AppInputText";
import AppLink from "@/components/AppLink";
import AppTitle from "@/components/AppTitle";
import FormSave from "@/mixins/FormSave";
import TheLayout from "@/components/TheLayoutAuth";

import gql from "graphql-tag";
import { mapGetters } from "vuex";

export default {
    components: {
        AppLink,
        AppFormGroup,
        AppTitle,
        AppHeader,
        AppButton,
        AppInputText,
        AppForm,
        TheLayout
    },
    mixins: [FormSave],
    data() {
        return {
            formValues: {
                email: ""
            },
            emptyFormErrors: {
                email: []
            }
        };
    },
    computed: {
        ...mapGetters("auth", ["userLoggedIn"]),
        ...mapGetters("online", ["onLine"])
    },
    watch: {
        userLoggedIn(value) {
            if (value) {
                this.$router.push({
                    name: "dashboard"
                });
            }
        }
    },
    methods: {
        getMutation() {
            return {
                mutation: gql`
                    mutation passwordReset($email: String!) {
                        passwordReset(input: { email: $email }) {
                            errors {
                                field
                                messages
                            }
                        }
                    }
                `,
                variables: {
                    email: this.formValues.email
                }
            };
        },
        formSuccess() {
            this.$router.push({
                name: "password_reset_done"
            });
        }
    }
};
</script>
