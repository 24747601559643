<template>
    <TheLayout>
        <AppArticle>
            <div class="u-margin-bottom">
                <p v-translate>
                    U heeft zojuist uw wachtwoord gewijzigd. U kunt verder gaan
                    met inloggen.
                </p>
            </div>
            <AppLink
                v-translate
                :to="{ name: 'login' }"
                variant="underline large"
            >
                Terug naar inloggen
            </AppLink>
        </AppArticle>
    </TheLayout>
</template>

<script>
import AppArticle from "@/components/AppArticle";
import AppLink from "@/components/AppLink";
import TheLayout from "@/components/TheLayoutAuth";
import { mapGetters } from "vuex";

export default {
    components: {
        AppLink,
        AppArticle,
        TheLayout
    },
    computed: mapGetters("auth", ["userLoggedIn"]),
    watch: {
        userLoggedIn(value) {
            if (value) {
                this.$router.push({
                    name: "dashboard"
                });
            }
        }
    }
};
</script>
