<template>
    <TheLayout>
        <AppArticle>
            <div class="u-margin-bottom">
                <p v-translate>
                    De instructies voor het wijzigen van uw wachtwoord zijn
                    zojuist naar u gemaild. Deze zult u spoedig ontvangen.
                </p>
                <p v-translate>
                    Mocht u de e-mail niet ontvangen, controleer in dat geval of
                    het zojuist ingevoerde e-mailadres in het systeem bekend is.
                    Controleer ook uw spambox.
                </p>
            </div>

            <AppLink
                v-translate
                :to="{ name: 'login' }"
                variant="underline large"
            >
                Terug naar inloggen
            </AppLink>
        </AppArticle>
    </TheLayout>
</template>

<script>
import AppArticle from "@/components/AppArticle";
import AppLink from "@/components/AppLink";
import TheLayout from "@/components/TheLayoutAuth";

import { mapGetters } from "vuex";

export default {
    components: {
        AppLink,
        AppArticle,
        TheLayout
    },
    computed: mapGetters("auth", ["userLoggedIn"]),
    watch: {
        userLoggedIn(value) {
            if (value) {
                this.$router.push({
                    name: "dashboard"
                });
            }
        }
    }
};
</script>
